<template>
  <div>
<!--    <currency-type :objectType="objectType"></currency-type>-->
    <data-acquisition :objectType="objectType"></data-acquisition>
  </div>
</template>
<script>
import CurrencyType from "@/components/FileType/CurrencyType";
import DataAcquisition from "@/components/FileType/DataAcquisition";
export default {
  data(){
    return{
      objectType: {
        Type: 6, // 原始数据
        FlowName: false,
        FileName: "原始数据"
      },
    }
  },
  components:{
    // CurrencyType,
    DataAcquisition
  },
};
</script>
