<template>
  <div class="finance-voucher-page">
    <div class="content_left">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="finance-voucher-content">
      <div class="voucher-header-upload">
        <div class="voucher-header-top">
          <span>文件名:</span>
          <el-input placeholder="请输入" v-model="FileName"></el-input>
          <span>上传开始日期:</span>
          <el-date-picker
            v-model="date1"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd">
          </el-date-picker>
          <span v-if="objectType.FlowName">所属阶段:</span>
          <el-input v-model="FlowName" placeholder="请输入阶段" v-if="objectType.FlowName"></el-input>
          <span>所属项目:</span>
          <el-input placeholder="请输入" v-model="TopicName" />
        </div>
        <div class="query-content">
          <div>
            <span>上传人员:</span>
            <el-input placeholder="请输入" v-model="UserName"></el-input>
            <span>上传结束日期:</span>
            <el-date-picker
              v-model="date2"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
          <el-button type="primary" icon="el-icon-search" style="right: 0" @click="queryAchieve">查询</el-button>
        </div>
        <el-divider></el-divider>
        <div  class="query-content">
          <el-button type="primary" icon="el-icon-top" @click="add">上传文件</el-button>
          <div>
            <el-button type="primary" icon="el-icon-bottom" @click="dowFiles">打包下载</el-button>
            <el-button type="primary" icon="el-icon-bottom" @click="exportFiles">导出表格</el-button>
            <el-button type="primary" icon="el-icon-delete" @click="delFiles">删除</el-button>
          </div>
        </div>
      </div>
      <div class="content" style="height: 520px">
        <el-table
          :data="tableData"
          style="width: 100%"
          fit
          max-height="520px"
          :header-cell-style="{
              color: '#333333',
              backgroundColor: '#FBFBFD',
            }"
          border
          ref="multipleTable"
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
          :reserve-selection="true"
            align="center"
            type="selection"
            width="50">
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="62">
          </el-table-column>
          <el-table-column
            label="文件名称"
            prop="FileName"
            align="center"
            min-width="150">
          </el-table-column>
          <el-table-column
            label="上传人员"
            prop="UserName"
            align="center"
            min-width="100">
          </el-table-column>
          <el-table-column
            label="上传时间"
            prop="CreateDate"
            align="center"
            min-width="130">
            <template slot-scope="scope">
              <span v-if="scope.row.CreateDate">{{scope.row.CreateDate.substring(0,10)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="采集数"
            prop="CollectNumber"
            align="center"
            min-width="80">
          </el-table-column>
          <el-table-column
            label="备注"
            prop="Remarks"
            align="center"
            min-width="120">
          </el-table-column>
          <el-table-column
            label="所属单位"
            prop="HospitalName"
            align="center"
            min-width="150">
          </el-table-column>
          <el-table-column
            prop="TopicName"
            label="所属项目"
            align="center"
            min-width="150">
          </el-table-column>
          <el-table-column label="操作" align="center" width="210">
            <template slot-scope="scope">
<!--              <el-link-->
<!--                style="margin-right: 10px"-->
<!--                type="text"-->
<!--                :underline="false"-->
<!--                @click="pre(scope.row)"-->
<!--              ><img src="../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看</el-link>-->
              <el-link
                style="margin-right: 10px"
                type="text"
                :underline="false"
                @click="edit(scope.row)"
              ><i class="el-icon-edit-outline"/>编辑</el-link>
              <el-link
                style="margin-right: 10px"
                type="text"
                :underline="false"
                @click="delFile(scope.row.Id)"
              ><i class="el-icon-delete"></i>删除</el-link>
              <el-link
                type="text"
                :underline="false"
                @click="downloadfile(scope.row.Id)"
              ><i class="el-icon-bottom"></i>下载</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="eachpage"
          layout="total,sizes,prev, pager, next, jumper"
          @size-change="handleSizeChange"
            :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
          :total="PageCount">
        </el-pagination>
      </div>
    </div>
    <div class="voucher-upload">
      <el-dialog
        :visible.sync="dialogVisible"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">上传文件</span>
        </div>
        <el-form ref="form" label-width="120px" :model="addForm">
          <el-form-item label="采集数:" prop="CollectNumber" :rules="[
          { required: true, message: '采集数不能为空',trigger: 'blur'},
          { type: 'number', message: '采集数必须为数字值',trigger: ['blur', 'change'] }
        ]">
            <el-input v-model.number="addForm.CollectNumber" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input type="textarea" v-model="Remarks" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
          </el-form-item>
          <el-form-item label="文件上传:" required>
            <el-upload
              class="upload-demo"
              ref="newupload"
              accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
              action="#"
              :limit="1"
              :file-list="fileList"
              :auto-upload="false"
              :on-change = "change"
              :on-remove="handleRemove"
              :before-upload="beforeUpload"
            >
              <el-button size="medium" type="primary" icon="el-icon-top">上传文件</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="btn">
          <button  @click="dialogVisible = false">取消</button>
          <el-button  @click="determine" :loading="loading" >确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="voucher-upload">
      <el-dialog
        :visible.sync="dialogeditVisible"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">编辑</span>
        </div>
        <el-form ref="editForm" label-width="120px" :model="editForm">
          <el-form-item label="采集数:" prop="CollectNumber" :rules="[
          { required: true, message: '采集数不能为空',trigger: 'blur'},
          { type: 'number', message: '采集数必须为数字值',trigger: ['blur', 'change'] }
        ]">
            <el-input v-model.number="editForm.CollectNumber" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input type="textarea" v-model="editForm.Remarks" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
          </el-form-item>
          <el-form-item label="附件:" required>
            <div class="del-img" @click.stop="pre(editItem)">
              <img src="../../assets/image/mor.png">
              <el-tooltip placement="top">
                <div slot="content">{{editItem.FileName}}.{{editItem.Suffix}}</div>
                <span class="ellip-span">{{editItem.FileName}}.{{editItem.Suffix}}</span>
              </el-tooltip>
              <i class="el-icon-view p-hover" style="position: absolute;bottom:38px;left:12px;color:#333;z-index: 9900" @click.stop="pre(editItem)"></i>
              <a style="position: absolute;bottom: 26px;left:82px;color: #333;z-index: 99990" @click.stop="downloadfile(editItem.Id)" class="p-hover">
                <span class="el-icon-download" style="font-size: 16px;margin-left: 10px"></span>
              </a>
            </div>
          </el-form-item>
        </el-form>
        <div class="btn">
          <button  @click="dialogeditVisible = false">取消</button>
          <button  @click="submitEdit">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="Achievements-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delAchievementsDialog"
        width="510px"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delAchievementsDialog = false">取消</button>
          <button @click="submitDelAchievements">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="Achievements-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFilesDialog"
        width="510px"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该选择的文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFilesDialog = false">取消</button>
          <button @click="submitDelLists">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import reseacrh from "@/api/research.js"
import {mapState} from "vuex";
import treedata from "@/components/treedata";
import {downloadFileId,downLoadXls} from "@/utils/downloadUrl.js"
import {exportTableType} from "@/api/fileManagement";
export default {
  data(){
    return{
      addForm: {
        CollectNumber: 0, // 采集数
      },
      exportData: "FileName,UserName,CreateDate,CollectNumber,Remarks,HospitalName,TopicName",
      exportDataName: "文件名称,上传人员,上传时间,采集数,备注,所属单位,所属项目",
      DataGuidList: [], // 打包文件的DataGuid数组
      date1: "",// 上传开始日期
      date2: "", // 上传结束日期
      UserName: "", // 用户名
      FlowName: "",//阶段名称
      FileName: "", // 文件名
      TopicName: "", //项目名称
      delFilesDialog: false, // 批量删除成果对象弹框
      delAchievementsDialog: false, // 删除单个成果对象弹框
      delId: null, // 删除成果Id
      editItem: {}, // 编辑item
      editForm: { // 编辑对象
      },
      dialogeditVisible:false, //编辑
      achivesForm: {
        date1: "",// 上传开始日期
        date2: "", // 上传结束日期
        fileName: "", // 上传文件名
        name: "", // 上传人员
        stage: "", // 所属阶段
        project: "",// 所属项目
      },
      selectItem: "",
      fileType: "", // 上传类型
      fileName: "", // 文件名称
      Remarks:"", // 备注说明
      fileList:[], // 上传文件列表
      dialogVisible:false, //上传文件弹框
      multipleSelection: [],//多选框数据
      tableData: [],
      ppt:"ppt",
      imglist:[],
      num:-1,
      value:"",
      currentPage:1,
      PageCount:0,
      eachpage:10,
      loading:false,
    }
  },
  computed: mapState(["projectId"]),
  components:{
    treedata
  },
  props: {
    objectType: {
      type:Object,
      defaullt: () => {}
    }
  },
  created(){
    this.filelist()
  },
  methods:{
    handleSizeChange(val){  //切换每页条数
      this.eachpage = val
      this.filelist()
    },
    getRowKey(row){     //回显
      return row.Id;
    },
    projectIdClick(data) { //树型结构传递过来的课题id
      this.$store.commit('SaveId',data)
      this.currentPage = 1;
      this.PageCount = 0;
      this.eachpage = 10;
      this.filelist()
    },
    changeOption() { // 选择之后查询
      this.filelist()
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList)
    },
    change(file,fileList) {
      this.fileList = fileList
      console.log(this.fileList)
    },
    determine(){ //确定文件上传
      if (this.fileList.length == 0) {
        this.$message.error('请上传文件')
      }
      this.$refs.newupload.submit();
    },
    beforeUpload(file){ // 选择的文件上传
      this.loading = true
      let objUpload = {
        Id: this.projectId,
        Type: this.objectType.Type,
        Remarks: this.Remarks,
        CollectNumber: this.addForm.CollectNumber
      };
      console.log(file);
      let fd = new FormData();
      fd.append("file", file);
      fd.append("data", JSON.stringify(objUpload));
      reseacrh.TopicUploadFile(fd).then((res) => {
        if (res.data.Status == 1) {
          this.dialogVisible = false;
          this.Remarks = "";
          this.filelist()
          this.loading =false
          this.$message.success(res.data.Message);
        }else{
          this.loading =false
          this.$message.error(res.data.Message)
        }
      });
    },
    add() { // 上传文件
      this.fileList = []
      this.dialogVisible=true
      this.Remarks = "";
      this.addForm.CollectNumber = 0
    },
    edit(item) { //编辑
      this.editItem = item
      this.dialogeditVisible = true
      reseacrh.TypeDataInfo(item.TypeId).then(res => {
        console.log(res)
        this.editForm = res.data.ExtraData
      })
    },
    submitEdit() { // 编辑确定提交
      reseacrh.TopicDataUpdateDataType(this.editForm).then( res=> {
        if (res.data.Status == 1) {
          this.dialogeditVisible = false
          this.filelist()
          this.$message.success(res.data.Message)
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    handleSelectionChange(val) { //多选
      this.multipleSelection = val;
    },
    handleCurrentChange(val){
      this.currentPage=val
      this.filelist()
    },
    pre(item){
      reseacrh.preview(item.Id).then(res=>{
        console.log(res);
        if(res.data.Status==1){
          let file = window.global_config.BASE_URL1+res.data.ExtraData.Url
          if(/\.(pdf|PDF|png|PNG|jpg|JPG|jpeg)$/.test(file)){
            window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
          }else{
            this.$message.warning("暂时只支持pdf文件预览")
          }
        }
      })
    },
    queryAchieve() {
      this.filelist()
    },
    filelist(){
      var time1
      var time2
      if (this.date1 == "" || this.date2) {
        time1= "";
        time2 = ""
      } else {
        time1 = this.date1 + ' '  +'00:00:00'
        time2 = this.date2 + ' ' +'23:59:59'
      }
      var fileobj={
        TopicId:this.projectId,
        Type: this.objectType.Type,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
        FileName:this.FileName,
        FlowName: this.FlowName,
        TopicName: this.TopicName,
        UserName: this.UserName,
        StartDate: time1,
        EndDate: time2
      }
      reseacrh.GetPagedTopicDataTypeData(fileobj).then(res=>{
        if(res.data.Status==1){
          // this.imglist=res.data.Entity
          this.tableData = res.data.Entity
          this.PageCount=res.data.TotalRowsCount
          console.log(res);
        }else{
          this.$message.warning(res.data.Message)
        }
      })
    },
    submitDelLists() { // 提交批量删除成果
      this.multipleSelection.forEach( (item,index)=>{
        reseacrh.alldelet({Id:item.Id}).then((res)=>{
          if (this.multipleSelection.length === index+1) {
            this.$message.success(res.data.Message)
            this.filelist()
          }
        })
      })
      this.delFilesDialog = false
    },
    delFiles() { // 批量删除文件
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请选择需要删除的文件')
        return false
      }
      this.delFilesDialog = true
    },
    submitDelAchievements() { // 确定删除单个任务文件
      reseacrh.alldelet({Id:this.delId}).then(res=>{
        if(res.data.Status==1){
          this.$message.success(res.data.Message)
          this.filelist()
          this.delAchievementsDialog = false
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    delFile(id){//删除单个任务文件弹框
      this.delAchievementsDialog = true
      this.delId = id
    },
    dowFiles() {
      this.DataGuidList = []
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请选择需要下载的文件')
        return false
      }
      this.multipleSelection.forEach(item => {
        this.DataGuidList.push(item.PDataGuid)
      })
      reseacrh.DownloadFileZip({DataGuids: this.DataGuidList}).then(res => {
        console.log(res,'通用打包下载')
        if (res.status == 200) {
          let data = res.data;
          let fileName = this.objectType.FileName + ".zip"
          downLoadXls(data,fileName)
        } else {
          this.$message.error("文件不存在")
        }
      })
    },
    downloadfile(id){
      downloadFileId(id)
    },
    exportFiles() {
      var time1
      var time2
      if (this.date1 == "" || this.date2) {
        time1= "";
        time2 = ""
      } else {
        time1 = this.date1 + ' '  +'00:00:00'
        time2 = this.date2 + ' ' +'23:59:59'
      }
      var exportobj={
        TopicId:this.projectId,
        Type: this.objectType.Type,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
        FileName:this.FileName,
        FlowName: this.FlowName,
        TopicName: this.TopicName,
        UserName: this.UserName,
        StartDate: time1,
        EndDate: time2,
        titleName: this.exportDataName,
        titleField: this.exportData,
        ExportFileName: this.objectType.FileName
      }
      exportTableType(exportobj).then(res=> {
        if (res.status == 200) {
          let data = res.data;
          let filename = decodeURIComponent(res.headers.filename)
          downLoadXls(data,filename)
        } else {
          this.$message.error("导出失败")
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/mixin.scss";
.finance-voucher-page {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  .content_left {
    width:260px;
    height: 94%;
    margin-top: 2%;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: white;
  }
  .finance-voucher-content{
    width: 83%;
    height: 94%;
    margin-left: 24px;
    margin-top: 2%;
    margin-right: 24px;
    background: #FFFFFF;
    padding: 1% 1% 0 1%;
    border-radius: 10px;
    box-sizing: border-box;
    .voucher-header-upload{
      width: 100%;
      margin-bottom: 20px;
      .voucher-header-top {
        margin-bottom: 10px;
        margin-left: 15px;
      }
      span {
        color: #69696A;
        @include add-size($font_size_16);
      }
      /deep/.el-select {
        @include add-size($font_size_16);
        width: 180px;
        height: 40px;
        margin-right: 30px;
      }
      /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 220px;
        @include add-size($font_size_16);
      }
      /deep/.el-button {
        @include add-size($font_size_16);
      }
      /deep/.el-input {
        width: 180px;
        height: 40px;
        @include add-size($font_size_16);
        margin-right: 20px;
        margin-left: 10px;
      }
    }
  }
}
.voucher-upload {
  /deep/.el-dialog {
    width: 578px;
    .el-form {
      .el-form-item {
        .el-form-item__label {
          @include add-size($font_size_16);
          color: #666666;
        }
        .el-input {
          @include add-size($font_size_16);
          width: 390px;
        }
        .el-textarea {
          @include add-size($font_size_16);
          width: 390px;
        }
      }
    }
  }
}
.block {
  margin-top: 20px;
  margin-bottom: 2%;
}
.content {
  ::-webkit-scrollbar {
    width: 8px!important;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #1890ff;
    border-radius: 10px;
  }
}
.query-content {
  display: flex;
  justify-content: space-between;
}
.p-hover:hover {
  cursor:pointer
}
.ellip-span {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
  height: 30px;
  display: block;
  position: absolute;
  bottom:10px;
  left: 2px;
  color: #606266;
  font-size: 12px
}
.del-img {
  width: 120px;
  height: 100px;
  position: relative;
  .img {
    width: 120px;
    height: 70px;
  }
}
</style>
